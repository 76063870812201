<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <!-- carrier services section -->
          <v-col cols="4">
            <draggable :group="{ name: 'carrierServices', pull: false, put: true }" ghostClass="display-none" draggable=".draggable">
              <h2 class="mb-4 text-h5">Carrier Services</h2>
              <v-text-field class="mb-4" v-model="carrierServicesFilter" outlined dense label="Search carrier services"></v-text-field>
              <draggable :list="filteredCarrierServices" :group="{ name: 'carrierServices', pull: 'clone', put: false }" drag-class="grabbing" ghostClass="ghost" :forceFallback="true">
                <div class="my-1" v-for="carrierService in filteredCarrierServices" :key="carrierService.id">
                  <v-chip class="to-grab white--text" :color="generateColor(carrierService.carrier_id, carrierService.id)"> {{ carrierService.service }}</v-chip>
                </div>
              </draggable>
            </draggable>
          </v-col>

          <!-- service groups section -->
          <v-col cols="8">
            <v-row>
              <v-col cols="12">
                <h2 class="text-h5">Service Groups</h2>
              </v-col>
              <v-col cols="4" v-for="(serviceGroup, serviceGroupIndex) in serviceGroups" :key="serviceGroup.id" align="center">
                <v-card outlined class="pa-2">
                  <!-- header -->
                  <h2 style="word-break: break-word">{{ serviceGroup.description }}</h2>
                  <div class="grey--text text-subtitle-2">{{ serviceGroup.service }}</div>
                  <v-divider class="py-0 my-2"></v-divider>
                  <!-- carriers - draggable section -->
                  <v-sheet rounded color="grey lighten-3">
                    <draggable
                      class="pa-1 drag-box"
                      :list="serviceGroup.carrierServices"
                      group="carrierServices"
                      @change="dragged(...arguments, serviceGroupIndex, serviceGroup.id, serviceGroup.carrierServices)"
                      drag-class="grabbing"
                      ghostClass="ghost"
                      :forceFallback="true"
                    > 
                       <div slot="header" class="ma-1 text-subtitle-2" v-show="serviceGroup.carrierServices.length < 1">Drag carrier service here</div>
                      <v-chip
                        small
                        :color="generateColor(carrierService.carrier_id, carrierService.id)"
                        class="ma-1 to-grab white--text"
                        v-for="(carrierService, index) in serviceGroup.carrierServices"
                        :key="index"
                      >
                        {{ carrierService.service }}
                      </v-chip>
                     
                    </draggable>
                    
                  </v-sheet>

                  <!-- <v-btn color="red" class="mt-2" outlined small @click="saveServiceGroup(serviceGroup.id, serviceGroup.carrierServices )" :loading="loading">Save</v-btn> -->

                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Carriers from '@/services/Carriers.js';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      validForm: true,
      // Title
      title: 'Carrier Service Groups',
      // Snackbar
      snackbar: false,
      text: '',
      loading: false,

      carrierServicesFilter: '',
      carrierServices: [],
      filteredCarrierServices: [],
      serviceGroups: [],
    };
  },

  watch: {
    carrierServicesFilter: {
      handler(newVal) {
        if (newVal) {
          // filter carriers by text that was searched
          let filter = newVal.toLowerCase();
          let filtered = this.carrierServices.filter((el) => el.service.toLowerCase().indexOf(filter) !== -1);
          this.filteredCarrierServices = filtered;
        } else {
          this.filteredCarrierServices = this.carrierServices;
        }
      },
    },
  },

  created() {
    this.getServiceGroups();
    this.getCarrierServices();
  },
  methods: {
    normaliseNum(range, val, min = 0.01) {
      const modul = val % range === 0 ? 1 : val % range;
      let OldRange = (range - 1)  
      let NewRange = (1 - min)  
      return (((modul - 1) * NewRange) / OldRange) + min
      
    },
    generateColor(primary) {
      const primaryRange = 25;
  
      let primaryVal = this.normaliseNum(primaryRange , primary + 14 ) ;
      return '#' + Math.floor(primaryVal * 16777215).toString(16);
    },
    dragged(evt, index, deliveryServiceId, carrierServices) {
      //  prevent duplicates
      if (evt.added) {
        let carrierServiceToAdd = evt.added.element;
        let filteredResults = this.serviceGroups[index].carrierServices.filter((el) => el.id !== carrierServiceToAdd.id);
        filteredResults.push(carrierServiceToAdd);
        this.serviceGroups[index].carrierServices = filteredResults;
      }
      this.saveServiceGroup(deliveryServiceId, carrierServices)
    },
    getCarrierServices() {
      this.loading = true
      Carriers.getCarrierServices()
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.carrierServices = response.data;
          this.filteredCarrierServices = this.carrierServices;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
        });
    },
    getServiceGroups() {
      this.loading = true
      Carriers.getServiceGroups(true)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.serviceGroups = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
        });
    },
    saveServiceGroup(deliveryServiceId, carrierServices){
      this.loading = true
      let carrierServiceIds = []
      for(let carrierService of carrierServices){
        let carrierServiceId = carrierService.id
        if(!carrierServiceIds.includes(carrierServiceId)){
          carrierServiceIds.push(carrierServiceId)
        }
      }
      let reqBody = {
        delivery_service_id: deliveryServiceId,
        carriers_service_ids: carrierServiceIds
      }
      Carriers.saveServiceGroup(reqBody)
        .then(() => {
          this.loading = false;
          // this.snackbar = true;
          // this.text = `${response.message}`;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
        });
    }
  },
};
</script>
<style>
h1 {
  font-size: 150% !important;
}
.display-none {
  display: none;
}
.ghost {
  opacity: 1;
  cursor: grabbing !important;
}
.grabbing {
  cursor: grabbing !important;
}
.to-grab {
  cursor: grab !important;
}
.v-chip {
  height: auto !important;
}

.v-chip .v-chip__content {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  white-space: pre-wrap;
}
.drag-box {
  min-height: 10vh;
}
</style>
